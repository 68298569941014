import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_biha/helpers'

const Error404: FC = () => {
  return (
    <div
      style={{
        height: 'calc(100vh - 140px)',
      }}
      className='d-flex flex-column align-items-center justify-content-center w-100'
    >
      <img
        src={toAbsoluteUrl('/media/auth/404-error.png')}
        className='mw-100 mh-300px theme-light-show'
        alt=''
      />
      <div className='d-flex flex-column align-items-center mt-8'>
        {/* <p fontSize={24} fontWeight='bold' sx={{color: theme.extension.palette.common.orange}}> */}
        <p className='fs-1 fw-bold' style={{color: '#ec6e00'}}>
          Không tìm thấy trang
        </p>
        <p className='mt-4'>Trang bạn đang tìm kiếm có thể đã bị xóa.</p>
      </div>
    </div>
  )
}

export {Error404}
