const QUERIES = {
  CONFIG: 'config',
  CUSTOMERS_LIST: 'customers-list',
  DEMAND_LIST: 'demand-list',
  PRODUCT_STOCK_LIST: 'product-stock-list',
  PRODUCT_ATM_LIST: 'product-atm-list',
  PROVINCE_LIST: 'province-list',
  DISTRICT_LIST: 'district-list',
  WARD_LIST: 'ward-list',
  STREET_LIST: 'street-list',
  COMMENT: 'comment',
  PROJECT_LIST: 'project-list',
  USER_LIST: 'user-list',
  CREATED_AND_UPDATED_BY: 'created-and-updated-by',
  INTERACTION_HISTORY: 'interaction-history',
}
const DEMAND_PATH = {
  MAIN: 'demands',
  LIST: 'list',
  OVERVIEW: 'overview',
  NEW: 'new',
  EDIT: 'edit',
}
const PHONE_REGEX = /^(0)(3[2-9]|5[2689]|7[0|6-9]|8[1-9]|9[0-9])[0-9]{7}$/
const NAME_REGEX = /^[A-Za-zÀ-ỹ\s]+$/
const SUB_QUERIES = {
  CONTRACT_CONFIGURATION: {
    ALL_CONTRACT_TYPE: 'all-contract-type',
    CONTRACT_TYPE_DETAIL: 'contract-type-detail',
    POST_CONTRACT: 'post-contract',
    UPDATE_CONTRACT: 'update-contract',
    DELETE_CONTRACT: 'delete-contract',
    ALL_EMPLOYEE: 'all-employee',
    ALL_CONTRACT: 'all-contract',
    CONTRACT_DETAIL: 'contract-detail',
  },
  HISTORY: {
    INTERACTION_HISTORY: 'interaction-history',
  },
}
const SUB_PATHS = {
  CUSTOMER: {
    INDEX: 'customers',
    SLUG: ':id',
    OVER_VIEW: 'over-view',
    DEMAND: 'demand',
    NOTE: 'note',
  },
  CONTRACT: {
    LIST: 'contract-list',
    INDEX: 'contracts',
    SLUG: ':contractId',
    OVER_VIEW: 'overview',
    INTERACTION_HISTORY: 'interaction-history',
    NOTE: 'note',
    EDIT_CONTRACT: 'edit-contract',
    NEW_CONTRACT: 'new-contract',
  },
}

export {DEMAND_PATH, NAME_REGEX, PHONE_REGEX, QUERIES, SUB_PATHS, SUB_QUERIES}
